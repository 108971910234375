import React, { useState } from "react";
import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  InputNumber,
  Modal,
  Radio,
  Select,
  Space,
} from "antd";

import TextArea from "antd/es/input/TextArea";
import moment from "moment";
import SubmitButton from "../Button";
import { FaDollarSign } from "react-icons/fa";
import AddressSelectionInput from "./AddressSelectionInput/AddressSelectionInput.view";

const CreateJobFormModal = ({
  visible,
  onClose,
  onSubmit,
  form,
  isSubmiting = false,
}) => {
  const [bidClosingDate, setBidClosingDate] = useState("");
  const [date, setdDate] = useState(null);
  const [haulingVal, setHaulingVal] = useState("Tons");
  const [mapModalVisible, setMapModalVisible] = useState(false);
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zipcode, setZipcode] = useState("");
  const [gps, setGps] = useState("");
  const [contractorMaterialSource, setContractorMaterialSource] = useState({
    address: "",
    city: "",
    state: "",
    zipcode: "",
    gps: "",
  });

  const [pickingMaterialSource, setPickingMaterialSource] = useState(false);
  const [addMaterial, setAddMaterial] = useState("");
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [selectedMaterialSource, setMaterialSource] = useState();
  const [selectedTypeOfOperation, setTypeOfOperation] = useState();

  const isOperationOnSiteHauling =
    selectedTypeOfOperation === "on-site-hauling";

  const isOperationExport = selectedTypeOfOperation === "export";

  const [material, setMaterial] = useState([
    "Sand",
    "Modified Subbase",
    "Special Backfill",
    "Rip Rap",
    "Pea Gravel",
    "Clean Stone",
    "Dirt",
    "Broken Concrete/Ashalt",
    "Crushed Concrete",
  ]);

  const onNameChange = (event) => {
    setAddMaterial(event.target.value);
  };

  const addItem = () => {
    if (addMaterial && !material.includes(addMaterial)) {
      const newMaterial = [...material, addMaterial];
      setMaterial(newMaterial);
      form.setFieldsValue({ material: addMaterial });
      setAddMaterial("");
      setDropdownVisible(false); // Close the dropdown
    }
  };

  const onChange = (date, dateStr) => {
    const timestamp = date && date.toDate().getTime();
    setdDate(dateStr);
    var timehrs = timestamp - 7200000;
    setBidClosingDate(new Date(timehrs));
    form.setFieldsValue({ bid_closing_date_ts: moment(timehrs) });
  };

  const handleFinish = async (values) => {
    const materialSourceType = values.materialSourceType;
    let contractorMaterialSource = undefined;

    if (materialSourceType !== undefined && materialSourceType == "1") {
      contractorMaterialSource = {
        gps: values.contractorMaterialSourceGps && {
          latitude: values.contractorMaterialSourceGps.split(",")[0],
          longitude: values.contractorMaterialSourceGps.split(",")[1],
        },
        address: values.contractorMaterialSourceAddress,
      };
    }

    const jobDateSplit = date.split(" ");

    let jobDate = {
      date: jobDateSplit[0],
      time: jobDateSplit[1],
      am_pm: jobDateSplit[2],
    };

    let bid_closing_date = {
      date: moment(form.getFieldValue("bid_closing_date_ts")).format(
        "YYYY-MM-DD"
      ),
      time: moment(form.getFieldValue("bid_closing_date_ts")).format(
        "hh:mm:ss"
      ),
      am_pm: moment(form.getFieldValue("bid_closing_date_ts")).format("A"),
    };

    const data = {
      form_input: {
        project_name: values.project_name,
        job_date_ts: jobDate,
        address: values.address,
        city: values.city,
        state: values.state,
        zipcode: values.zipcode,
        taxExcempt: values.taxExcempt,
        materialSourceType: values.materialSourceType,
        contractorMaterialSource: contractorMaterialSource,
        gps: values.gps && {
          latitude: values.gps.split(",")[0],
          longitude: values.gps.split(",")[1],
        },
        perspectiveProject: values.perspectiveProject,
        process_type: values.process_type,
        material: values.material,
        hauling_type: values.hauling_type,
        special_instructions: values.special_instructions,
        budget: values.budget,
        hourly_minimum: values.minimum,
        quantity: values.quantity,
        bid_closing_date_ts: bid_closing_date,
      },
    };

    await onSubmit(data);
    resetForm();
  };

  const handleLocationCoordinated = (value) => {
    let lat_lng_val =
      value && value.lat.toFixed(2) + "," + value.lng.toFixed(2);

    if (pickingMaterialSource) {
      form.setFieldsValue({ contractorMaterialSourceGps: lat_lng_val });
      setContractorMaterialSource((prev) => ({
        ...prev,
        gps: lat_lng_val,
      }));
    } else {
      setGps(lat_lng_val);
      form.setFieldsValue({ gps: lat_lng_val });
    }

    setMapModalVisible(false);
  };

  const disabledDate = (current) => {
    // Can not select days before today and today
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    return current && current.toDate() < today;
  };

  const hauling = [
    { label: "Ton", value: "Ton" },
    { label: "Yard", value: "Yard", disabled: true },
    { label: "Hourly", value: "Hourly", disabled: true },
  ];

  const resetForm = () => {
    form.resetFields();
    setGps("");
    setContractorMaterialSource({
      address: "",
      city: "",
      state: "",
      zipcode: "",
      gps: "",
    });
    setAddress("");
    setCity("");
    setState("");
    setZipcode("");
    setMapModalVisible(false);
    setPickingMaterialSource(false);
  };

  const close = () => {
    resetForm();
    if (onClose) {
      onClose();
    }
  };

  const budgetUnit = () => {
    if (haulingVal === "Hourly") {
      return "/Hour";
    } else if (haulingVal === "Ton") {
      return "/Ton";
    } else if (haulingVal === "Yard") {
      return "/Yard";
    }
  };

  return (
    <Modal
      title={"Create New Job"}
      open={visible}
      maskClosable={false}
      width={600}
      onCancel={close}
      style={{ top: 60 }}
      destroyOnClose={true}
      footer={false}
      zIndex={1}
    >
      <div
        style={{
          maxHeight: "calc(100vh - 200px)",
          overflowY: "auto",
          maxWidth: "100%",
        }}
      >
        <Form
          form={form}
          onFinish={handleFinish}
          layout="vertical"
          name="nest-messages"
          labelCol={{ span: 18 }}
          wrapperCol={{ span: 22 }}
          style={{ maxWidth: 700, margin: "15px 30px" }}
          initialValues={{ hauling_type: haulingVal }}
          autoComplete="off"
        >
          <div className="flex flex-wrap">
            <div className="w-full sm:w-1/2 md:w-1/2 lg:w-1/2 xl:w-1/2">
              <div>
                <Form.Item
                  name={"project_name"}
                  label={"Project Name"}
                  rules={[
                    { required: true, message: `Project Name is required!` },
                  ]}
                >
                  <Input />
                </Form.Item>
              </div>
              <div className="flex-1">
                <Form.Item name={"perspectiveProject"}>
                  <label className="flex flex-row gap-2">
                    <Checkbox
                      onChange={(e) => {
                        form.setFieldsValue({
                          perspectiveProject: e.target.checked,
                        });
                      }}
                    />
                    <span className="">Perspective Project</span>
                  </label>
                </Form.Item>
              </div>
              <div className="">
                <Form.Item
                  name={"process_type"}
                  label={"Type of Operation"}
                  rules={[{ required: true, message: `Job Type is required!` }]}
                >
                  <Radio.Group
                    value="import"
                    className="flex flex-col gap-2"
                    onChange={(e) => {
                      setTypeOfOperation(e.target.value);
                      if (e.target.value === "on-site-hauling") {
                        form.setFieldsValue({
                          hauling_type: hauling[2].value,
                          materialSourceType: undefined,
                        });
                        setHaulingVal(hauling[2].value);
                      } else {
                        form.setFieldsValue({
                          hauling_type: hauling[0].value,
                        });
                        setHaulingVal(hauling[0].value);
                      }
                    }}
                  >
                    <Radio value="import">Import</Radio>
                    <Radio value="export">Export</Radio>
                    <Radio value="on-site-hauling">On-site Hauling</Radio>
                  </Radio.Group>
                </Form.Item>
              </div>

              <div className="">
                <Form.Item
                  name={"material"}
                  label={"Material"}
                  rules={[{ required: true, message: `Material is required!` }]}
                >
                  <Select
                    options={material.map((item) => ({
                      label: item,
                      value: item,
                    }))}
                    onChange={(selected) => {
                      form.setFieldsValue({ material: selected });
                    }}
                    dropdownRender={(menu) => (
                      <div>
                        {menu}
                        <Divider style={{ margin: "8px 0" }} />
                        <Space style={{ padding: "0 8px 4px" }}>
                          <Input
                            placeholder="Please enter item"
                            value={addMaterial}
                            onChange={onNameChange}
                            onKeyDown={(e) => e.stopPropagation()}
                          />
                          <Button type="primary" size="small" onClick={addItem}>
                            Add Other
                          </Button>
                        </Space>
                      </div>
                    )}
                    onDropdownVisibleChange={(open) => setDropdownVisible(open)}
                    open={dropdownVisible}
                  />
                </Form.Item>
              </div>

              <div className="">
                <Form.Item
                  name={"budget"}
                  label={"Budget"}
                  rules={[{ required: true, message: `Budget is required!` }]}
                >
                  <InputNumber
                    addonBefore={<FaDollarSign />}
                    addonAfter={budgetUnit()}
                    style={{ width: "100%" }}
                  />
                </Form.Item>
              </div>

              {!isOperationOnSiteHauling && (
                <div className="">
                  <Form.Item
                    name={"quantity"}
                    label={"Quantity"}
                    rules={[
                      { required: true, message: `Quantity is required!` },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </div>
              )}
            </div>
            <div className="w-full sm:w-1/2 md:w-1/2 lg:w-1/2 xl:w-1/2">
              <div>
                <Form.Item
                  name={"date"}
                  label={"Date"}
                  rules={[{ required: true, message: `Date is required!` }]}
                >
                  <DatePicker
                    showTime={{ format: "HH:mm", use12Hours: true }}
                    disabledDate={disabledDate}
                    onChange={onChange}
                  />
                </Form.Item>
              </div>
              <div className="flex-1">
                <Form.Item name={"taxExcempt"}>
                  <label className="flex flex-row gap-2">
                    <Checkbox
                      onChange={(e) => {
                        form.setFieldsValue({ taxExcempt: e.target.checked });
                      }}
                    />
                    <span className="">Tax Exempt</span>
                  </label>
                </Form.Item>
              </div>

              <div className="">
                {!isOperationOnSiteHauling && (
                  <Form.Item
                    name={"materialSourceType"}
                    label={isOperationExport ? "Dispose" : "Material Source"}
                    rules={[
                      { required: true, message: `Job Type is required!` },
                    ]}
                  >
                    <Radio.Group
                      value="materialSourceType"
                      className="flex flex-col gap-2"
                      onChange={(e) => {
                        setMaterialSource(e.target.value);
                        if (e.target.value == "0") {
                          setContractorMaterialSource({
                            address: "",
                            city: "",
                            state: "",
                            zipcode: "",
                            gps: "",
                          });
                        }
                      }}
                    >
                      <Radio value="1">
                        {isOperationExport
                          ? "To location Designated"
                          : "Contractor Supplied Material"}
                      </Radio>
                      <Radio value="0">
                        {isOperationExport
                          ? "Trucker Dispose"
                          : "Trucker Supplied Material"}
                      </Radio>
                      <div className="h-[22px]"></div>
                    </Radio.Group>
                  </Form.Item>
                )}
              </div>

              <div className="">
                <Form.Item
                  name={"hauling_type"}
                  label={"Hauling Type"}
                  rules={[
                    { required: true, message: `Hauling Type is required!` },
                  ]}
                >
                  <Select
                    disabled={isOperationOnSiteHauling}
                    options={hauling}
                    values={haulingVal}
                    onChange={(selected) => {
                      setHaulingVal(selected);
                    }}
                  />
                </Form.Item>
              </div>

              <div className="">
                <Form.Item
                  name={"bid_closing_date_ts"}
                  label={"Bid Closing Date"}
                >
                  <DatePicker
                    disabledDate={disabledDate}
                    showTime={{ format: "HH:mm", use12Hours: true }}
                  />
                </Form.Item>
              </div>

              <div className="">
                <Form.Item
                  name={"special_instructions"}
                  label={"Additional Information"}
                >
                  <TextArea rows={2} />
                </Form.Item>
              </div>
            </div>
          </div>
          <div className="flex flex-row gap-2"></div>
          <div className="">
            <h1 className="text-lg">Project Site</h1>
            <AddressSelectionInput
              form={form}
              required={true}
              addressName="address"
              gpsName="gps"
            />
          </div>

          {selectedMaterialSource && selectedMaterialSource == 1 && (
            <div className="">
              <h1 className="text-lg">Material Site</h1>

              <AddressSelectionInput
                form={form}
                required={true}
                addressName="contractorMaterialSourceAddress"
                gpsName="contractorMaterialSourceGps"
              />
            </div>
          )}

          {Object.keys(form.getFieldsError()).map((field) => (
            <div key={field} style={{ color: "red" }}>
              {form.getFieldError(field)}
            </div>
          ))}
          <Col
            style={{
              justifyContent: "flex-end",
              display: "flex",
              marginTop: "15px",
              marginRight: "18px",
            }}
          >
            <Button style={{ marginRight: "10px" }} onClick={close}>
              Cancel
            </Button>
            <div
              className={`${
                isSubmiting ? "opacity-50 pointer-events-none" : ""
              }`}
            >
              <SubmitButton form={form}>Submit Job</SubmitButton>
            </div>
          </Col>
        </Form>
      </div>
    </Modal>
  );
};

export default CreateJobFormModal;
