import { capitalise } from "../utils/string";

class HaulAssignmentHelper {
  constructor(data) {
    this.data = data;
  }

  get status() {
    return capitalise(this.data.status);
  }

  get haulId() {
    return this.data._id;
  }

  get isExpired() {
    const jobDate = this.data.date.split("T")[0];
    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);

    const jobDateObject = new Date(jobDate);
    jobDateObject.setHours(0, 0, 0, 0);

    return currentDate > jobDateObject;
  }

  get job() {
    return this.data.job;
  }

  get isExportJob() {
    return this.job.process_type === "export";
  }

  get completedPercentage() {
    const quantity = parseInt(this.data.job.quantity);
    const percent = (this.job.tons_completed / quantity) * 100;
    return percent.toFixed(0);
  }

  get tonsCompleted() {
    return this.data.tons_completed;
  }

  get remainingQuantity() {
    const quantity = parseInt(this.data.job.quantity);
    return quantity - this.tonsCompleted;
  }

  get assignments() {
    return this.data.assignments ?? [];
  }

  assignmentById(id) {
    return this.assignments.find((a) => a._id === id);
  }

  getLastAssignment() {
    return this.assignments.length > 0
      ? this.assignments[this.assignments.length - 1]
      : null;
  }

  set tonsCompleted(tons) {
    this.data.tons_completed = tons;
  }
}

export default HaulAssignmentHelper;
