import { apiHelper } from "../../../api/instance";

export const requestPaymentSessionToken = async (payload) => {
  const { amount, firstName, lastName } = payload;

  const body = {
    ssl_amount: amount,
    ssl_first_name: firstName,
    ssl_last_name: lastName,
  };

  const response = await apiHelper.post("/invoice?action=make_payment", body);
  return response.data;
};
