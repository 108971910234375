import {
  Button,
  Col,
  Form,
  Input,
  Modal,
  Popconfirm,
  Row,
  Select,
  Table,
} from "antd";
import React, { useEffect } from "react";
import { useState } from "react";
import $ from "jquery";
import Toastr from "toastr";
import SubmitButton from "../components/Button";
import { API } from "../config/AppConstants";
import { AiOutlineDelete } from "react-icons/ai";
import useSession from "../context/SessionContext";

const Users = () => {
  const { user } = useSession();
  const [form] = Form.useForm();
  const [modalVisible, setModalVisible] = useState(false);
  const [roles, setRoles] = useState(null);
  const [register, setRegister] = useState(false);
  const [subRoles, setSubRoles] = useState(null);
  const [userOption, setUserOption] = useState(null);
  const [users, setUsers] = useState(null);
  const [loading, setLoading] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);

  useEffect(() => {
    if (user) {
      getUsers();
      fetchRoles();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const user_columns = [
    {
      title: "First Name",
      dataIndex: "first_name",
      render: (user_name, record, i) => {
        return <span className="anchor_link">{user_name}</span>;
      },
      width: "150px",
      sortDirections: ["ascend", "descend", "ascend"],
      onCell: (record, recordIndex) => ({
        onClick: () => onCellSelected(record),
      }),
    },
    {
      title: "Last Name",
      dataIndex: "last_name",
      render: (user_name, record, i) => {
        return <span>{user_name}</span>;
      },
      width: "150px",
      sortDirections: ["ascend", "descend", "ascend"],
    },
    {
      title: "Login ID",
      dataIndex: "user_login",
      render: (user_login, record, i) => {
        return <span>{user_login}</span>;
      },
      width: "auto",
      sortDirections: ["ascend", "descend", "ascend"],
    },
    {
      title: "Role",
      dataIndex: "role_id",
      render: (user_role, record, i) => {
        let userRole =
          subRoles && subRoles.find((s) => s.role_id === user_role);
        return <span>{userRole && userRole.role_name}</span>;
      },
      width: "150px",
      sortDirections: ["ascend", "descend", "ascend"],
    },
    {
      title: "Email",
      dataIndex: "user_login",
      render: (user_email, record, i) => {
        return <span>{user_email}</span>;
      },
      width: "150px",
      sortDirections: ["ascend", "descend", "ascend"],
    },
    {
      title: "Phone",
      dataIndex: "phone",
      render: (user_phone1, record, i) => {
        return <span>{user_phone1}</span>;
      },
      width: "150px",
      sortDirections: ["ascend", "descend", "ascend"],
    },
  ];

  const onCellSelected = (record) => {
    record.role = subRoles.find((s) => s.role_id === record.role_id).role_name;
    setSelectedRow(record);
    form.setFieldsValue(record);
    setModalVisible(true);
  };

  const addUser = () => {
    form.setFieldsValue({
      user_login: "",
      first_name: "",
      last_name: "",
      password: "",
      phone: "",
      role: "",
    });
    setModalVisible(true);
  };

  const getUsers = () => {
    setLoading(true);
    $.ajax({
      url:
        API.API_URL +
        API.API_VER +
        API.API_ENDPOINTS.LOGIN.USERS +
        "?company_id=" +
        user.company_id,
      type: "GET",
      dataType: "json",
      headers: {
        user_login: user.user_login,
        user_token: user.user_token,
      },
    })
      .then(async (res) => {
        let subUsers = res.filter((user) => user.role_type !== "global");
        setLoading(false);
        setUsers(subUsers);
      })
      .fail(function (jqXHR, textStatus, errorThrown) {
        setLoading(false);
      });
  };

  const fetchRoles = () => {
    $.ajax({
      url:
        API.API_URL +
        API.API_VER +
        API.API_ENDPOINTS.ROLE.ROLES +
        "?role_type=" +
        user.role_id,
      type: "GET",
      dataType: "json",
      headers: {
        user_login: user.user_login,
        user_token: user.user_token,
      },
    })
      .then(async (res) => {
        setSubRoles(res);
        let roles = [];
        for (const role of res) {
          roles.push({ value: role.role_id, label: role.role_name });
        }
        setRoles(roles);
      })
      .fail(function (jqXHR, textStatus, errorThrown) {});
  };

  const handleModalClose = () => {
    setModalVisible(false);
    setSelectedRow(null);
  };

  const onFinish = (values) => {
    if (selectedRow) {
      updateUser(values);
    } else {
      saveUser(values);
    }
  };

  const updateUser = (values) => {
    let modifiedFields = getModifiedFields(selectedRow, values);
    let form_input = {
      user_login: selectedRow.user_login,
      user_pass: values.password,
    };
    for (const val in modifiedFields) {
      if (val === "role") {
        form_input["role_id"] = modifiedFields[val];
        continue;
      }
      form_input[val] = modifiedFields[val];
    }
    let data = { form_input: form_input };
    $.ajax({
      url: API.API_URL + API.API_VER + API.API_ENDPOINTS.LOGIN.USERS,
      type: "PUT",
      contentType: "application/json; charset=utf-8",
      dataType: "json",
      headers: {
        user_login: user.user_login,
        user_token: user.user_token,
      },
      data: JSON.stringify(data),
    }).then(
      (res) => {
        if (res) {
          Toastr.success("User Updated successfully!");
          form.resetFields();
          setModalVisible(false);
          getUsers();
          setSelectedRow(null);
        }
      },
      (err) => {
        Toastr.error("Something went wrong!");
      }
    );
  };

  const getModifiedFields = (obj1, obj2) => {
    const differingValues = {};
    for (let key in obj1) {
      if (obj2.hasOwnProperty(key) && obj1[key] !== obj2[key]) {
        differingValues[key] = obj2[key];
      }
    }
    return differingValues;
  };

  const saveUser = (values) => {
    setRegister(true);
    let value = { ...values, company_id: user.company_id };
    let selectedRole = subRoles.find((s) => s.role_id === value.role);
    let data = {
      form_input: {
        user_login: value.user_login,
        password: value.password,
        role_id: selectedRole.role_id,
        role_type: selectedRole.role_type,
        first_name: value.first_name.trim(),
        last_name: value.last_name.trim(),
        phone: value.phone,
        email: value.user_login,
        company_id: value.company_id,
      },
    };
    $.ajax({
      url: API.API_URL + API.API_VER + API.API_ENDPOINTS.LOGIN.SIGNUP,
      type: "POST",
      contentType: "application/json; charset=utf-8",
      dataType: "json",
      headers: {
        user_login: user.user_login,
        user_token: user.user_token,
      },
      data: JSON.stringify(data),
    }).then(
      (res) => {
        if (res) {
          const hasError = res.error;
          if (!hasError) {
            Toastr.success("User registered successfully!");
            setRegister(false);
            setModalVisible(false);
            getUsers();
            form.resetFields();
          } else {
            Toastr.error("User already exist!");
            setRegister(false);
            form.resetFields();
          }
        }
      },
      (err) => {
        Toastr.error("Something went wrong!");
        setRegister(false);
      }
    );
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const valiDateNumber = (_, value) => {
    const regPattern = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
    if (value && !regPattern.test(value)) {
      return Promise.reject("Please enter valid number.");
    }
    return Promise.resolve();
  };

  const validatePassword = (_, value) => {
    if (value && value.length < 5) {
      return Promise.reject("Password must be atleast 5 characters long.");
    }
    return Promise.resolve();
  };

  const onSelectChange = (newSelectedRowKeys, usersToDelete) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const deleteUser = (user_login) => {
    return new Promise((resolve, reject) => {
      let data = {
        form_input: {
          user_login: user_login,
        },
      };
      $.ajax({
        url: API.API_URL + API.API_VER + API.API_ENDPOINTS.LOGIN.USERS,
        type: "DELETE",
        contentType: "application/json; charset=utf-8",
        dataType: "json",
        headers: {
          user_login: user.user_login,
          user_token: user.user_token,
        },
        data: JSON.stringify(data),
      }).then(
        (res) => {
          if (res) {
            resolve("user deleted successfully!");
          }
        },
        (err) => {
          if (err.status === 200) {
            resolve("user deleted successfully!");
          }
          resolve("Something went wrong!");
        }
      );
    });
  };

  const handleDelete = async () => {
    for (const user_login of selectedRowKeys) {
      let result = await deleteUser(user_login);
    }
    Toastr.success("User deleted successfully!");
    getUsers();
    setSelectedRowKeys([]);
  };

  const showPagination = users && users.length > 10;
  return (
    <div>
      <Row>
        <Col span={24} style={{ display: "flex", justifyContent: "flex-end" }}>
          <Button type="primary" onClick={() => addUser()}>
            Add User
          </Button>
          <Popconfirm
            title="Delete User"
            description={
              selectedRowKeys.length > 1
                ? "Are you sure to delete these Users?"
                : "Are you sure to delete this User?"
            }
            okText="Yes"
            cancelText="No"
            onConfirm={handleDelete}
          >
            <Button
              type="primary"
              style={{ marginLeft: "6px" }}
              disabled={!selectedRowKeys.length > 0}
              danger
              icon={<AiOutlineDelete size={20} />}
            ></Button>
          </Popconfirm>
        </Col>
      </Row>
      <Row style={{ marginTop: "15px" }}>
        <Col span={24}>
          <div className="ant-table-custom-wrapper">
            <Table
              columns={user_columns}
              dataSource={users}
              pagination={showPagination ? {} : false}
              rowKey={(record) => record.user_login}
              loading={loading}
              rowSelection={rowSelection}
            />
          </div>
        </Col>
      </Row>
      <Modal
        title={selectedRow ? "Edit User" : "Add User"}
        maskClosable={false}
        open={modalVisible}
        width={600}
        onCancel={handleModalClose}
        style={{ top: 60 }}
        destroyOnClose={true}
        footer={false}
      >
        <div
          style={{
            maxHeight: "calc(100vh - 200px)",
            overflowY: "auto",
            maxWidth: "100%",
          }}
        >
          <Form
            form={form}
            layout="vertical"
            name="nest-messages"
            labelCol={{ span: 18 }}
            wrapperCol={{ span: 22 }}
            style={{ maxWidth: 700, margin: "15px 30px" }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <Row>
              <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                <Form.Item
                  label={"First Name"}
                  name={"first_name"}
                  rules={[
                    { required: true, message: `First name is required!` },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                <Form.Item
                  label={"Last Name"}
                  name={"last_name"}
                  rules={[
                    { required: true, message: `Last name is required!` },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                <Form.Item
                  label={"Email / Login ID"}
                  name={"user_login"}
                  rules={[
                    {
                      required: true,
                      message: `Email / Login ID is required!`,
                    },
                    {
                      type: "email",
                      message: "The input is not valid E-mail!",
                    },
                  ]}
                >
                  <Input autoComplete="off" disabled={selectedRow} />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                <Form.Item
                  label={"Password"}
                  name={"password"}
                  rules={
                    !selectedRow && [
                      { required: true, message: `Password is required!` },
                      { validator: validatePassword },
                    ]
                  }
                >
                  <Input.Password disabled={selectedRow} />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                <Form.Item
                  label={"Contact Phone Number"}
                  name={"phone"}
                  rules={[
                    {
                      required: true,
                      message: `Contact Phone Number is required!`,
                    },
                    { validator: valiDateNumber },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                <Form.Item
                  label={"Role"}
                  name={"role"}
                  rules={[{ required: true, message: `Role is required!` }]}
                >
                  <Select
                    options={roles}
                    values={userOption}
                    onChange={(selected) => {
                      setUserOption(selected);
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Col
              style={{
                justifyContent: "flex-end",
                display: "flex",
                marginTop: "15px",
                marginRight: "18px",
              }}
            >
              <Button
                style={{ marginRight: "10px" }}
                onClick={() => setModalVisible(false)}
              >
                Cancel
              </Button>
              <SubmitButton form={form}>
                {register ? "Registering..." : "Submit"}
              </SubmitButton>
            </Col>
          </Form>
        </div>
      </Modal>
    </div>
  );
};

export default Users;
