import { StatusEnum } from "../enums/status";
import { capitalise } from "../utils/string";

class JobHelper {
  constructor(jobData) {
    this.jobData = jobData;
  }

  get jobId() {
    return this.jobData.job_id;
  }

  get contractorMaterialSourceGps() {
    return this.jobData.contractorMaterialSource?.gps;
  }

  get contractorMaterialSourceAddress() {
    return this.jobData.contractorMaterialSource?.address;
  }

  get isAssigned() {
    return this.jobStatus !== StatusEnum.UNASSIGNED;
  }

  get bid_id() {
    return this.jobData.bid_id;
  }

  get isPrivateJob() {
    return this.jobData.private === true;
  }

  get projectName() {
    return this.jobData.project_name;
  }

  get haulingType() {
    return this.jobData.hauling_type;
  }

  get isExportJob() {
    return this.jobData.process_type === "export";
  }

  get isTaxExempt() {
    return this.jobData.taxExcempt === true;
  }

  get isPerpectiveProject() {
    return this.jobData.perspectiveProject === true;
  }

  get isJobExpired() {
    const jobDate = this.jobData.job_date_ts.split("T")[0];
    const currentDate = new Date();
    const jobDateObject = new Date(jobDate);
    return currentDate > jobDateObject;
  }

  get isImportJob() {
    return this.jobData.process_type === "import";
  }

  get gpsCoordinates() {
    const gpsString = this.jobData?.gps;
    if (gpsString != null) {
      const gps = gpsString.split(",");
      return {
        lat: parseFloat(gps[0]),
        lng: parseFloat(gps[1]),
      };
    }
    return { lat: 0, lng: 0 };
  }

  get isGpsNotAvailable() {
    return this.gpsCoordinates.lat === 0 && this.gpsCoordinates.lng === 0;
  }

  get gps() {
    return this.jobData.gps;
  }

  get companyDetails() {
    const data = {};
    if (this.jobData.company_details) {
      data.firstName = this.jobData.company_details.first_name;
      data.lastName = this.jobData.company_details.last_name;
      data.phoneNumber = this.jobData.company_details.phone;
    }
    return data;
  }

  get totalMaterial() {
    return this.jobData.quantity ?? 0;
  }

  get completedTons() {
    return this.jobData.tons_completed ?? 0;
  }

  get address() {
    const data = {};
    data.address = this.jobData?.address || "";
    data.city = this.jobData?.city || "";
    data.state = this.jobData?.state || "";
    data.zipcode = this.jobData?.zipcode || "";
    return data;
  }

  get specialInstructions() {
    return this.jobData.special_instructions ?? "-";
  }

  get completedInPercent() {
    const totalQuantity = this.totalMaterial;
    const completedQuantity = this.completedTons;
    const percentComplete = Math.round(
      (completedQuantity / totalQuantity) * 100
    );
    return percentComplete;
  }

  get isTransferLimitReached() {
    return this.completedInPercent >= 100;
  }

  get processType() {
    return this.jobData.process_type;
  }

  //set job status as assinged
  setAssigned() {
    this.jobData.job_status = StatusEnum.ASSIGNED;
  }

  get materialType() {
    return this.jobData.material;
  }

  get companyName() {
    return this.jobData.business_name;
  }

  get jobStatus() {
    const status = this.jobData.haul_status ?? "Unassigned";
    return capitalise(status);
  }

  set jobStatus(status) {
    this.jobData.job_status = status;
  }

  get budget() {
    return this.jobData.budget;
  }

  get bidClosingDate() {
    return this.jobData.bid_closing_date_ts;
  }

  // Additional utility methods
  get isJobContracted() {
    return this.jobData.job_status === "Contracted";
  }

  get isCompleted() {
    return this.jobData.job_status === "Completed";
  }

  get totalBids() {
    return this.jobData.bids.length;
  }

  bidById(bidId) {
    return this.jobData.bids.find((bid) => bid.bid_id === bidId);
  }

  get allBids() {
    return this.jobData.bids;
  }

  addBid(bid) {
    this.jobData.bids.push(bid);
  }

  removeBid(bidId) {
    this.jobData.bids = this.jobData.bids.filter((bid) => bid.bid_id !== bidId);
  }

  get isContractorMaterialSource() {
    return this.jobData.materialSourceType === "1";
  }

  get getMaterialSourceGpsCoordinates() {
    const gps = this.jobData.contractorMaterialSource?.gps ?? null;
    if (gps != null) {
      return {
        lat: parseFloat(gps.latitude),
        lng: parseFloat(gps.longitude),
      };
    }
    return null;
  }

  get isHourlyJob() {
    return this.jobData.hauling_type === "Hourly";
  }
}

export default JobHelper;
