import { API } from "../../config/AppConstants";
import { apiHelper } from "../instance";

export const fetchDriverJobs = async ({
  date,
  method = "getDriverJobAssignments",
}) => {
  const { data } = await apiHelper.get(API.API_ENDPOINTS.TRUCKER.HAULS, {
    params: {
      date,
      method,
    },
  });
  return data;
};

export const startTrip = async (payload) => {
  const { data } = await apiHelper.post(
    API.API_ENDPOINTS.DRIVER.TRIPS,
    payload
  );
  return data;
};

export const updateTripStatus = async (payload) => {
  const { data } = await apiHelper.put(API.API_ENDPOINTS.DRIVER.TRIPS, payload);
  return data;
};

export const initiateHourlyTrip = async ({ job_assignment_id, trip_date }) => {
  const { data } = await apiHelper.post(
    API.API_ENDPOINTS.DRIVER.HOURLY_TRIP.INITIATE,
    { job_assignment_id, trip_date }
  );
  return data;
};

export const pauseHourlyTrip = async ({ trip_id, end_time, operation }) => {
  const { data } = await apiHelper.post(
    API.API_ENDPOINTS.DRIVER.HOURLY_TRIP.PAUSE,
    { trip_id, end_time, operation }
  );
  return data;
};

export const resumeHourlyTrip = async ({ trip_id, start_time }) => {
  const { data } = await apiHelper.post(
    API.API_ENDPOINTS.DRIVER.HOURLY_TRIP.RESUME,
    { trip_id, start_time }
  );
  return data;
};
