import {
  Outlet,
  RouterProvider,
  createBrowserRouter,
  useNavigate,
  useParams,
} from "react-router-dom";
import useSession from "./SessionContext";
import Login from "../auth/login";
import ForgotPassword from "../auth/forgot-password";
import { ErrorPage } from "../components/Error";
import DashBoard from "../Dashboard";
import Register from "../auth/register";
import { Button, Drawer, Dropdown, Flex, List, Space, Typography } from "antd";
import truckitezWhiteLogo from "../assets/img/logo_transparent_white.png";
import { Content, Header } from "antd/es/layout/layout";
import AppConstants from "../config/AppConstants";
import Upload from "../components/upload";
import Users from "../roles/users";
import { useEffect } from "react";
import LogOut from "../auth/logout";
import Settings from "../components/settings";
import Mines from "../roles/quarry/mines";
import Fleet from "../roles/trucker/fleet";
import Bids from "../roles/contractor/bids";
import Docs from "../roles/docs";
import { useState } from "react";
import BusinessProfile from "../roles/businessProfile";
import { FaCircleUser } from "react-icons/fa6";
import truckitnow_svg from "./../assets/img/truckit_now-cropped.svg";
import truckitlater_svg from "./../assets/img/truckit_later-cropped.svg";
import truckitmaybe_svg from "./../assets/img/truckit_maybe-cropped.svg";
import Jobs from "../roles/job_status/jobs";
import InvoiceTemplate from "../InvoiceTemplate";
import Inbox from "../components/Inbox";
import { MenuOutlined, MailOutlined, ShopOutlined } from "@ant-design/icons";
import ProjectDetails from "../screens/Projects/ProjectDetails.view";
import LoadProjects from "../screens/Projects/Projects.view";
import DriverProjects from "../screens/DriverProjects/DriverProjects.view";
import DriverProjectDetails from "../screens/DriverProjects/DriverProjectDetails.view";
import ManageDriverProject from "../components/modules/ManageDriverProject/ManageDriverProject.view";
import Invoices from "../screens/Invoices/Invoices.view";
import { isDriver, isContractor, isTruckingCompany } from "../utils/roles";
import DriverProjectDetailsContainer from "../screens/DriverProjects/DriverProjectDetails.container";

export default function MobileRouter() {
  const Layout = () => {
    const { user, setUser } = useSession();
    const navigate = useNavigate();
    let params = useParams();
    const [route, setRoute] = useState("K001");
    const [open, setOpen] = useState(false);
    const [highlightedItem, setHighlightedItem] = useState(null);

    const isRoleDriver = isDriver();
    const isRoleContractor = isContractor();
    const isRoleTruckingCompany = isTruckingCompany();

    function getUserAccessibleRoutes(routes) {
      return routes.filter((n) => {
        if (n.children) {
          n.children = getUserAccessibleRoutes(n.children);
        }
        return n.roles.includes(user && user.role_id);
      });
    }
    let menuItems = getUserAccessibleRoutes(AppConstants().NAV_MENU);
    if (menuItems.length === 1) menuItems = menuItems[0].children;

    let URL = window.location.href;
    let isUploadUrl = !URL.includes("upload");

    useEffect(() => {
      if (!user || !user.user_login) {
        return navigate("/", { replace: true });
      }
    });

    useEffect(() => {
      let selectedElement = menuItems.find((e) => {
        return e.path.split("/")[2] === params.job_status;
      });
      setHighlightedItem(selectedElement || menuItems[0]);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const selectJobType = (job_type) => {
      let userObj = { ...user };
      userObj["job_type"] = job_type;
      setUser(userObj);
      navigate("/projects");
    };

    const onClose = () => {
      setOpen(false);
    };

    const handleClick = (item) => {
      setHighlightedItem(item);
      navigate(item.path);
      setOpen(false);
    };

    let nav_item = isUploadUrl
      ? AppConstants().NAV_SETTINGS.filter((n) =>
          n.roles.includes(user && user.role_id)
        )
      : AppConstants().NAV_SETTINGS.filter(
          (n) => n.roles.includes(user && user.role_id) && n.key !== "R00000"
        );

    return (
      <div>
        <Header
          style={{
            position: "sticky",
            top: 0,
            zIndex: 1,
            width: "100%",
            display: "flex",
            alignItems: "center",
          }}
        >
          {/* {isUploadUrl && <Dropdown menu={{ items: menuItems }}> */}
          <span
            onClick={(e) => {
              e.preventDefault();
              setOpen(true);
            }}
          >
            <Space>
              <MenuOutlined />
              <div className="menu-spacer"></div>
            </Space>
          </span>
          {/* </Dropdown>} */}

          <span
            className="flex items-center"
            onClick={(e) => {
              if (isUploadUrl) {
                if (isRoleDriver) {
                  navigate("/driver/projects");
                } else if (isRoleContractor) {
                  navigate("/projects");
                } else if (isRoleTruckingCompany) {
                  navigate("/projects");
                }
              }
              setHighlightedItem(menuItems[0]);
            }}
          >
            <img
              style={{ marginTop: "25px", borderRadius: "5px" }}
              alt="TruckingEZ"
              width="100px"
              height="18px"
              src={truckitezWhiteLogo}
            ></img>
          </span>
          <Drawer
            title={"Project Name: "}
            styles={{ header: { display: "none" } }}
            placement="left"
            onClose={onClose}
            open={open}
            mask={true}
            width={250}
          >
            <List
              bordered={false}
              dataSource={menuItems}
              renderItem={(item) => {
                return (
                  <div>
                    <List.Item
                      style={{ paddingLeft: "13px", borderRadius: "6px" }}
                      className={
                        highlightedItem &&
                        highlightedItem.label.props.children ===
                          item.label.props.children
                          ? "highlighted"
                          : ""
                      }
                      onClick={() => handleClick(item)}
                    >
                      {item.label.props.children}
                    </List.Item>
                  </div>
                );
              }}
            />
          </Drawer>
          <Flex justify={"flex-end"} align="center" style={{ width: "100%" }}>
            <Space mode={"vertical"}>
              {isUploadUrl && (
                <Button
                  type="link"
                  onClick={() => {
                    setRoute("");
                    navigate("/inbox");
                  }}
                  style={{ color: "white" }}
                >
                  <MailOutlined />
                </Button>
              )}
              <div>
                <Dropdown
                  menu={{
                    items: getUserAccessibleRoutes(
                      AppConstants().BUSINESS_MENU_SETTINGS
                    ),
                  }}
                >
                  <Typography.Link
                    className="text-white"
                    onClick={(e) => e.preventDefault()}
                    style={{ textTransform: "capitalize" }}
                  >
                    {isUploadUrl && (
                      <Button
                        type="link"
                        onClick={() => {
                          setRoute("");
                          navigate("/business-profile");
                        }}
                        style={{ color: "white", marginRight: "15px" }}
                      >
                        <ShopOutlined />
                      </Button>
                    )}
                  </Typography.Link>
                </Dropdown>
              </div>
              <Dropdown menu={{ items: nav_item }}>
                <Typography.Link
                  className="text-white"
                  onClick={(e) => e.preventDefault()}
                  style={{ textTransform: "capitalize" }}
                >
                  <Flex align="center" style={{ textTransform: "capitalize" }}>
                    <FaCircleUser
                      style={{ fontSize: "20px", marginRight: "5px" }}
                    />
                    <span className="loggedin-user">
                      {user && [user.first_name, user.last_name].join(", ")}
                    </span>
                  </Flex>
                </Typography.Link>
              </Dropdown>
            </Space>
          </Flex>
        </Header>
        {window.location.pathname.startsWith("/projects") && (
          <center>
            <Flex
              className="flex-image-strip gap-6 p-8"
              justify={"space-evenly"}
              vertical={true}
              style={{ marginTop: "10px" }}
            >
              <img
                id="truckItNow"
                alt="TruckItNow"
                style={{
                  opacity: user && user.job_type === "TruckItNow" ? 1 : 0.2,
                  cursor: "pointer",
                  width: "100%",
                }}
                onClick={() => selectJobType("TruckItNow")}
                src={truckitnow_svg}
              ></img>
              <img
                id="truckItLater"
                alt="TruckItLater"
                style={{
                  opacity: user && user.job_type === "TruckItLater" ? 1 : 0.2,
                  cursor: "pointer",
                  width: "100%",
                }}
                onClick={() => selectJobType("TruckItLater")}
                src={truckitlater_svg}
              ></img>
              <img
                id="truckItMaybe"
                alt="TruckItMaybe"
                style={{
                  opacity: user && user.job_type === "TruckItMaybe" ? 1 : 0.2,
                  cursor: "pointer",
                  width: "100%",
                }}
                onClick={() => selectJobType("TruckItMaybe")}
                src={truckitmaybe_svg}
              ></img>
            </Flex>
          </center>
        )}
        <Content style={{ minHeight: "100vh" }} className="content">
          <div className="content-spacer">
            <Outlet />
          </div>
        </Content>
      </div>
    );
  };

  const BrowserRoutes = createBrowserRouter([
    { path: "/", element: <Login /> },
    { path: "/welcome", element: <Login /> },
    { path: "/register", element: <Register /> },
    { path: "/forgot-password", element: <ForgotPassword /> },
    {
      path: "/",
      element: <Layout />,
      children: [
        { path: "/dashboard", element: <DashBoard /> },
        { path: "/users", element: <Users /> },
        { path: "/mines", element: <Mines /> },
        { path: "/fleet", element: <Fleet /> },
        { path: "/docs", element: <Docs /> },
        { path: "/bids", element: <Bids /> },
        { path: "/upload", element: <Upload /> },
        { path: "/documents", element: <Docs /> },
        { path: "/business-profile", element: <BusinessProfile /> },
        { path: "/settings", element: <Settings /> },
        { path: "/logout", element: <LogOut /> },
        { path: "/invoices", element: <Invoices /> },
        {
          path: "/invoice/:bid_id/:job_id/:company_id",
          element: <InvoiceTemplate />,
        },

        {
          path: "/manage/projects/:job_id",
          element: <ProjectDetails />,
          exact: true,
        },

        {
          path: "/load-projects",
          element: <LoadProjects />,
          exact: true,
        },
        {
          path: "/driver/projects",
          element: <DriverProjects />,
          exact: true,
        },

        {
          path: "/driver/projects/:assignment_id",
          element: <DriverProjectDetailsContainer />,
          exact: true,
        },
        {
          path: "/manage/driver/projects/:assignment_id",
          element: <ManageDriverProject />,
          exact: true,
        },
        { path: "/invoice/:invoice_id", element: <InvoiceTemplate /> },
        { path: "/projects", element: <Jobs />, exact: true },
        { path: "/inbox", element: <Inbox /> },
        { path: "/projects/:job_status", element: <Jobs /> },
        { path: "/page-error", element: <ErrorPage /> },
        { path: "*", element: <ErrorPage /> },
      ],
    },
  ]);

  return <RouterProvider router={BrowserRoutes} />;
}
