export const Mutations = {
  truckerHauls: "trucker-hauls",
  startDriverTrip: "start-driver-trip",
  updateTripStatus: "update-trip-status",
  updateHaul: "update-haul",
  initiateHourlyTrip: "initiate-hourly-trip",
  pauseHourlyTrip: "pause-hourly-trip",
  resumeHourlyTrip: "resume-hourly-trip",
  createJob: "create-job",
  makePayment: "make-payment",
};
